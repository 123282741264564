<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Assign Employee Shift</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'employee-shifts'}">
                        <span>Employee Shifts</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Create</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Employee
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <EmployeeList :multipleEmployees="multipleEmployees" :reporting_to="employeeReportingto" @emp_id="getEmpId"></EmployeeList>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Shift
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-form-select 
                                v-model="EmployeeShift.shift_id"
                                :options="shifts"
                            ></b-form-select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            From Date
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-input-group size="md" class="mb-1em">
                                <date-pick
                                    v-model="EmployeeShift.from_date"
                                    :inputAttributes="{readonly: true}"
                                    :displayFormat="'DD-MM-YYYY'"
                                    :isDateDisabled="isFutureDate"
                                ></date-pick>
                            </b-input-group>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            To Date
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-input-group size="md" class="mb-1em">
                                <date-pick
                                    v-model="EmployeeShift.to_date"
                                    :inputAttributes="{readonly: true}"
                                    :displayFormat="'DD-MM-YYYY'"
                                    :isDateDisabled="isFutureDate"
                                ></date-pick>
                            </b-input-group>
                        </div>
                    </div>

                    <div class="form-group row">
                            <label class="col-md-3">
                                Week Offs
                                <span class="text-danger" style="font-weight:bold;">*</span>
                            </label>

                            <div class="col-md-9">
                                <multiselect
                                    label="name"
                                    track-by="value"
                                    placeholder="Select Week off"
                                    v-model="selected_week_offs"
                                    :options="week_offs"
                                    :multiple="true"
                                    :close-on-select="false"
                                    :taggable="true"
                                ></multiselect>
                            </div>
                    </div>

                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="assignEmployeeShift()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-secondary ma-10px"
                                @click="$router.go(-1)"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
import default_error from "../../../assets/js/global";
import EmployeeList from "@/components/EmployeeList.vue";
import Multiselect from 'vue-multiselect';

export default {
    components: {
        NotFound,
        DatePick,
        EmployeeList,
        Multiselect
    },
    data() {
        return {
            employeeReportingto:1,
            EmployeeShift: {
                emp_id:"",
                shift_id:"",
                from_date:"",
                to_date:"",
                week_off:''
            },
            shifts: [
                {value: "", text:"Select Shift"}
            ],
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            week_offs: [
                {value: 'Monday', name: "Monday"},
                {value: 'Tuesday', name: "Tuesday"},
                {value: 'Wednesday', name: "Wednesday"},
                {value: 'Thursday', name: "Thursday"},
                {value: 'Friday', name: "Friday"},
                {value: 'Saturday', name: "Saturday"},
                {value: 'Sunday', name: "Sunday"}
            ],
            selected_week_offs:"",
            multipleEmployees:true,
        };
    },
    created() {
        this.access = this.hasAnyPermission(['CAN_ASSIGN_SHIFTS']);
        this.getShifts();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getEmpId(id) {
            this.EmployeeShift.emp_id = id;
        },

        assignEmployeeShift: function() {
            this.startProgressBar();
            if(this.selected_week_offs) {
                let weekoff = [];
                this.selected_week_offs.forEach(week_offs => {
                weekoff.push(week_offs.value);
            })
            this.EmployeeShift['week_off'] = weekoff;  
            } 
            const query = this.getAuthHeaders();
            const data = this.deleteEmptyKeys(this.EmployeeShift);
            query["params"] = {};
            this.axios
                .post(this.$api.assign_employee_shift, data, query)
                .then(response => {
                    if (response.data.error) {
                        this.apiError = response.data.message;
                        this.updateProgressBar(true);
                        return;
                    }
                    this.$router.push({ name: "employee-shifts" });
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.EmployeeShift.week_off = "";
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },

        getShifts: function(){
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {};
            this.axios
                .get(this.$api.get_working_shift_list, query)
                .then(response => {
                    response.data.data.forEach(shift => {
                        this.shifts.push({
                            value:shift.id, text: shift.name
                        })
                    });
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        isFutureDate(date) {
            const yesterday_date = new Date(new Date().setDate(new Date().getDate() - 1));
            return date < yesterday_date;
        },
    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "../../../assets/css/custom.scss";

.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
}
.vdpClearInput {
    display: none;
}
.vdpComponent.vdpWithInput > input {
    padding-right: 0px;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}

.badge-red-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
</style>